import React, { useEffect, useRef, useState, useCallback } from 'react';
import '../styles/LandingPage.css';

const DESKTOP_PARTICLE_COUNT = 3000;
const MOBILE_PARTICLE_COUNT = 200;
const SPHERE_RADIUS_FACTOR = 0.25;
const DESKTOP_REPEL_FORCE = 25;
const MOBILE_REPEL_FORCE = 25;
const DESKTOP_RETURN_FORCE = 0.01;
const MOBILE_RETURN_FORCE = 0.01;
const DESKTOP_DRAG = 0.98;
const MOBILE_DRAG = 0.95;
const DESKTOP_MAX_SPEED_FACTOR = 0.1;
const MOBILE_MAX_SPEED_FACTOR = 0.075;

const LandingPage = () => {
  const canvasRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const particlesRef = useRef([]);
  const pointerRef = useRef({ x: 0, y: 0 });
  const isMobileRef = useRef(false);
  const animationRef = useRef(null);
  const lastInteractionTimeRef = useRef(0);

  const checkMobile = useCallback(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileKeywords = ['android', 'webos', 'iphone', 'ipad', 'ipod', 'blackberry', 'windows phone'];
    return mobileKeywords.some(keyword => userAgent.includes(keyword));
  }, []);

  const initializeParticles = useCallback(() => {
    const { width, height } = dimensions;
    const sphereRadius = Math.min(width, height) * SPHERE_RADIUS_FACTOR;
    const centerX = width / 2;
    const centerY = height / 2;
    const particleCount = isMobileRef.current ? MOBILE_PARTICLE_COUNT : DESKTOP_PARTICLE_COUNT;

    particlesRef.current = Array.from({ length: particleCount }, () => {
      const theta = Math.random() * 2 * Math.PI;
      const phi = Math.acos((Math.random() * 2) - 1);
      const x = centerX + sphereRadius * Math.sin(phi) * Math.cos(theta);
      const y = centerY + sphereRadius * Math.sin(phi) * Math.sin(theta);
      return {
        x, y, baseX: x, baseY: y,
        radius: Math.random() * 2 + 1,
        vx: 0,
        vy: 0,
        baseRadius: Math.random() * 2 + 1,
      };
    });
  }, [dimensions]);

  const animate = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const { width, height } = dimensions;
    const sphereRadius = Math.min(width, height) * SPHERE_RADIUS_FACTOR;
    const maxDistance = sphereRadius * 4;

    ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
    ctx.fillRect(0, 0, width, height);

    const currentTime = Date.now();
    const timeSinceLastInteraction = currentTime - lastInteractionTimeRef.current;

    particlesRef.current.forEach(particle => {
      let dx = pointerRef.current.x - particle.x;
      let dy = pointerRef.current.y - particle.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      const repelForce = isMobileRef.current ? MOBILE_REPEL_FORCE : DESKTOP_REPEL_FORCE;
      const returnForce = isMobileRef.current ? MOBILE_RETURN_FORCE : DESKTOP_RETURN_FORCE;
      const drag = isMobileRef.current ? MOBILE_DRAG : DESKTOP_DRAG;
      const maxSpeedFactor = isMobileRef.current ? MOBILE_MAX_SPEED_FACTOR : DESKTOP_MAX_SPEED_FACTOR;

      if (distance < sphereRadius * 2 && timeSinceLastInteraction < 2000) {
        const angle = Math.atan2(dy, dx);
        const force = Math.min(1, (sphereRadius * 2 - distance) / (sphereRadius * 2));
        particle.vx -= Math.cos(angle) * force * repelForce;
        particle.vy -= Math.sin(angle) * force * repelForce;
      }

      const speed = Math.sqrt(particle.vx * particle.vx + particle.vy * particle.vy);
      const maxSpeed = maxDistance * maxSpeedFactor;
      if (speed > maxSpeed) {
        particle.vx = (particle.vx / speed) * maxSpeed;
        particle.vy = (particle.vy / speed) * maxSpeed;
      }

      particle.x += particle.vx;
      particle.y += particle.vy;

      const distanceFromBase = Math.sqrt(
        (particle.x - particle.baseX) ** 2 + (particle.y - particle.baseY) ** 2
      );
      particle.vx += (particle.baseX - particle.x) * returnForce;
      particle.vy += (particle.baseY - particle.y) * returnForce;

      particle.vx *= drag;
      particle.vy *= drag;

      particle.radius = particle.baseRadius + Math.sin(Date.now() * 0.01) * 0.5;

      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
      const gradient = ctx.createRadialGradient(
        particle.x, particle.y, 0,
        particle.x, particle.y, particle.radius
      );
      gradient.addColorStop(0, 'rgba(160, 32, 240, 1)');
      gradient.addColorStop(1, 'rgba(160, 32, 240, 0)');
      ctx.fillStyle = gradient;
      ctx.fill();
    });

    animationRef.current = requestAnimationFrame(animate);
  }, [dimensions]);

  useEffect(() => {
    isMobileRef.current = checkMobile();

    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const handlePointerMove = (e) => {
      if (isMobileRef.current) {
        e.preventDefault(); // Prevent scrolling on mobile
      }
      const rect = canvasRef.current.getBoundingClientRect();
      const clientX = e.clientX || (e.touches && e.touches[0].clientX);
      const clientY = e.clientY || (e.touches && e.touches[0].clientY);
      pointerRef.current = {
        x: clientX - rect.left,
        y: clientY - rect.top,
      };
      lastInteractionTimeRef.current = Date.now();
    };

    window.addEventListener('resize', handleResize);
    
    if (isMobileRef.current) {
      window.addEventListener('touchmove', handlePointerMove, { passive: false });
      window.addEventListener('touchstart', handlePointerMove, { passive: false });
    } else {
      canvasRef.current.addEventListener('mousemove', handlePointerMove);
    }

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      if (isMobileRef.current) {
        window.removeEventListener('touchmove', handlePointerMove);
        window.removeEventListener('touchstart', handlePointerMove);
      } else {
        canvasRef.current.removeEventListener('mousemove', handlePointerMove);
      }
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [checkMobile]);

  useEffect(() => {
    if (dimensions.width > 0 && dimensions.height > 0) {
      initializeParticles();
      animate();
    }
  }, [dimensions, initializeParticles, animate]);

  return (
    <div className="landing-page">
      <canvas 
        ref={canvasRef} 
        className="particle-canvas"
        width={dimensions.width}
        height={dimensions.height}
      />
      <div className="content">
        <h1>Stellar</h1>
        <p>FORGING THE FUTURE OF ISOTOPES</p>
      </div>
      <div className="copyright">
        © 2024 Stellar Atomics Inc.
      </div>
    </div>
  );
};

export default LandingPage;